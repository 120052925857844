<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    transition="dialog-bottom-transition"
    max-width="600"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins h-100">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add New Payment Type</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="toggleModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!--        <v-btn rounded color="primary" @click="dialog = false">-->
        <!--          Create-->
        <!--        </v-btn>-->
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--        entities form-->

        <form id="createForm" class="row pt-4">
          <div class="col-12 my-0 py-0">
            <v-text-field
              v-model="formData.system_code"
              label="system code"
              clearable
              outlined
              dense
              :error-messages="system_codeErrors"
              @input="$v.formData.system_code.$touch()"
              @blur="$v.formData.system_code.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 my-0 py-0">
            <v-text-field
              v-model="formData.label"
              label="label"
              clearable
              dense
              outlined
              :error-messages="labelErrors"
              @input="$v.formData.label.$touch()"
              @blur="$v.formData.label.$touch()"
            ></v-text-field>
          </div>

          <v-main class="text-center">
            <v-btn class="mr-2" color="primary" @click="submitCreateForm">
              submit
            </v-btn>
            <v-btn @click="resetCreateForm"> clear </v-btn>
          </v-main>
        </form>
      </div>

      <div style="flex: 1 1 auto"></div>
    </div>
  </v-dialog>
</template>

<script>
// import { UPDATE_ENTITYCOLUMNS_DATA } from "@/core/services/store/entities.module";
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      system_code: { required },
      label: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    carrier: null,
    formData: {
      system_code: null,
      label: null,
    },
  }),
  beforeMount() {
    // this.loadDataFromServer();
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/cod/payment_types/create")
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },
    toggleModal() {
      if (!this.dialog) {
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },
    validateData() {
      return true;
    },
    convertToFormData() {
      // let data = new FormData();
      // Object.entries(this.formData).forEach((item) => {
      //   if ((item[1] && item[0] != "image") || item[1] == 0) {
      //     data.append(item[0], item[1]);
      //   } else if (item[1] && item[0] == "image") {
      //     data.append(item[0], item[1][0]);
      //   }
      // });

      return this.formData;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if (this.validateData()) {
          this.pageLoader(true);
          // let data = JSON.stringify(this.entCreate);
          let data = this.convertToFormData();
          ApiService.post("/cod/payment_types/store", data)
            .then(() => {
              Swal.fire({
                title: "Created",
                text: `Payment Type has been successfully added!`,
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              this.toggleModal();
              this.resetCreateForm();
              this.refresher();
            })
            .catch(() => {
              this.pageLoader(false);
            });
        } else {
          return null;
        }
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        system_code: null,
        label: null,
      };
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      // return !!this.serverData;
      return true;
    },
    system_codeErrors() {
      return this.handleFormValidation("system_code", this);
    },
    labelErrors() {
      return this.handleFormValidation("label", this);
    },
  },
};
</script>
